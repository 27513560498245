(function ($, window, document, undefined) {
    'use strict';

    /**
     * Project Filter
     */
    $.widget('nsm.projectFilter', {
        /**
         * Options
         */
        options: {
            classes: {
                selectedClass: 'ProjectSummaryGroup-filterControl--isCurrent'
            },
            selectors: {
                filterableProjects: '.js-filterableSummary',
                filterableProjectsParent: '.js-filterableProjects',
                filterButtons: '.js-projectFilterButton',
                filterSelect: '.js-projectFilterSelect',
                filterEmptyMessage: '.ProjectSummaryGroup-listMessage',
            },
            activeFilter: '*'
        },


        /**
         * Create the widget
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('projectFilterOptions'));
            this.filterableProjects = $(this.widget).find(this.options.selectors.filterableProjects),
            this.filterableProjectsParent = $(this.widget).find(this.options.selectors.filterableProjectsParent),
            this.filterButtons = $(this.widget).find(this.options.selectors.filterButtons),
            this.filterSelect = $(this.widget).find(this.options.selectors.filterSelect),

            this._on(this.filterSelect, {change: '_onSelectChange'});
            this._on(this.filterButtons, {click: '_onButtonClick'});

            this.options.activeFilter = Cookies.get('active-project-filter') || '*';

            // Initi Isotope layout
            this.grid = this.filterableProjectsParent.isotope({
              // Options
              itemSelector: this.options.selectors.filterableProjects,
              layoutMode: 'packery',
              packery: {
                columnWidth: '.ProjectSummary',
              },
              percentPosition: true,
              sortBy : 'random',
              fitWidth: true,

              // Disable scale transform transition when hiding
              hiddenStyle: {
                opacity: 0
              },
              visibleStyle: {
                opacity: 1
              }
            });

            this._setProjectFilter();
        },

        /**
         * Handle Widget Click
         *
         * @param event
         * @private
         */
        _onSelectChange: function (event) {
            this.options.activeFilter = $(event.target).find('option:selected').data('filter');
            this._setProjectFilter();
        },

        _onButtonClick: function (event) {
            this.options.activeFilter = $(event.target).data('filter');
            this._setProjectFilter();
        },

        _setProjectFilter: function() {
            // Update isotope and re-order layout
            this.grid.isotope({ filter: this.options.activeFilter });

            // Show message if there are no entires for a Category
            if(!this.grid.data('isotope').filteredItems.length) {
                // Remove selected class from empty message, and add it back to he current one.
                $(this.widget).find(this.options.selectors.filterEmptyMessage).removeClass(this.options.classes.selectedClass);
                $(this.widget).find(this.options.selectors.filterEmptyMessage).addClass(this.options.classes.selectedClass);
            } else {
                $(this.widget).find(this.options.selectors.filterEmptyMessage).removeClass(this.options.classes.selectedClass);
            }

            // Remove selected class from all the buttons, and add it back to he current one.
            this.filterButtons.removeClass(this.options.classes.selectedClass);
            this.filterButtons.filter('[data-filter~="'+this.options.activeFilter+'"]').addClass(this.options.classes.selectedClass);

            // Remove selected property from all the select options, and add it back to he current one.
            this.filterSelect.find('option').prop('selected', false);
            this.filterSelect.find('option[data-filter~="'+this.options.activeFilter+'"]').prop('selected', true);

            //  Update cookies (Displays slected Project Filter across sections of the site)
            Cookies.set('active-project-filter', this.options.activeFilter);
        }

    });

})(jQuery, window, document);
