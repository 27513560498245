$(function(){

    'use strict';

    // Resize and rinitialise the Facbook page widget so it's responsive.
    $( window ).resize(function() {
        if (this.resizeCall) {
            clearTimeout(this.resizeCall);
        }
        this.resizeCall = setTimeout(function() {
            // $(this).trigger('resizeEnd');
        }, 500);
    });

    $(window).bind('resizeEnd', function() {
        console.log("resizing fb");
        var $fbPageWrapper = $('#FacebookPageWrapper'),
            containerWidth = $fbPageWrapper.width();

        $fbPageWrapper.html('<div class="fb-page" data-href="https://www.facebook.com/uonarchitecture" data-width="' + containerWidth + '" data-height="530" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" data-show-posts="true"><div class="fb-xfbml-parse-ignore"><blockquote cite="https://www.facebook.com/uonarchitecture"><a href="https://www.facebook.com/uonarchitecture">Facebook</a></blockquote></div></div>');
        FB.XFBML.parse();
    });

    // End Facebook Resize code.

    $('body').on('contentcreated', function (event) {
        event.stopPropagation();

        // Setup the carousels
        $('.js-Carousel').slick({
            dots: false,
            autoplay: true,
            autoplaySpeed: 3500,
            pauseOnHover: false,
            cssEase: 'cubic-bezier(0.77, 0, 0.175, 1)',
            speed: 1200
        });

        //  Controls Isotope layout options and filterable content
        $('.js-filterableProjects').projectFilter();

        $('.js-accordionToggle').click( function(event) {
            var $wrapper = $(event.target).closest('.Accordion');
            $wrapper.toggleClass('is-open');
            $wrapper.find('.Accordion-content').slideToggle();
        });

        // Setup the image galleries
        $('.js-ImageGallery').each( function() {
            // Get the photoswipe template
            var $pswp = $('.pswp')[0];

            var $pic = $(this),
                getItems = function() {
                    var items = [];
                    $pic.find('a').each(function() {
                        var $href   = $(this).attr('href'),
                            $size   = $(this).data('size').split('x'),
                            $width  = $size[0],
                            $height = $size[1];

                        var item = {
                            src : $href,
                            w   : $width,
                            h   : $height
                        };

                        items.push(item);
                    });
                    return items;
                };

            var items = getItems();

            $pic.on('click', 'a', function(event) {
                event.preventDefault();

                var imageIndex = $(this).data('index');
                console.log(imageIndex);
                var options = {
                    index: imageIndex,
                    bgOpacity: 0.8,
                    showHideOpacity: true
                };

                // Initialize PhotoSwipe
                var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                lightBox.init();
            });
        });

    }).trigger('contentcreated');

});
